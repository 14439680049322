body[data-theme="light"] {
  @import "../src/scss/light-slider.scss";

  .modal-tab {
    background-color: #5f8bb0;
  }

  .navbar {
    background-color: #f4f2f2 !important;
    border-bottom: 2px solid #5f8ab0;
  }

  .navbarLink {
    color: black !important;
  }

  #contact-form-button {
    color: #343a40;
    border-color: #343a40;
  }

  #formspree-div a {
    color: #5f8bb0;
  }
}
